
.App_Grid__n2kJa {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: auto auto auto auto auto;
  grid-template-rows: auto auto auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;

}

@media only screen and (max-device-width: 480px) {
  .App_Grid__n2kJa {
    display: block;
    font-size: 16px;
  }
}

@media only screen and (max-device-width: 400px) {
  .App_Grid__n2kJa {
    display: block;
    font-size: 13px;
    font-weight: 300;
  }
}

.App_icon__1IEmS {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}



.App_Child__33rEh {
  min-height: 600px;
  max-width: 600px;
  min-width: 90%;
/*
  background: linear-gradient(
      16deg,
      rgb(11, 30, 56) 0%,
      rgb(10, 37, 73) 42%,
      rgb(8, 45, 95) 100%
    ),
    linear-gradient(to right, rgb(37, 62, 99), rgb(11, 31, 70));

  background-repeat: no-repeat;
  background-origin: padding-box, border-box;
  */
  border-radius: 0em;
  grid-column: 3;
  grid-row: 2;
  text-align: center;
  color: #f9f0b0b3;
  /*
  -webkit-box-shadow: 0px 0px 800px 20px rgba(19, 75, 99, 0.44);
  box-shadow: 0px 0px 800px 20px rgba(19, 75, 99, 0.44);
  */
}

@media only screen and (max-device-width: 400px) {
  .App_tab-list-item__1L3r0 {
    min-width: none;
  }
}


.App_curtain__NVWhD {
  background-color: rgba(0, 0, 0, 0.466);
  position: static;
  height: 99em;
  width: auto;
  grid-column-start: 1;
  grid-column-end: 6;
  z-index: 2;
  filter: blur(8px);
  -webkit-backdrop-filter: blur(1.5em);
  backdrop-filter: blur(1.5em);
}


a {
  color:#f9f0b0b3;
  text-decoration: none;
}


.App_none__3TF6w {
 position: none;
}

.App_topframe__10R-O {
  position: absolute;
  top: 1px;
  left: 1px;
  font-size: 0.3em;
}

.App_wallet__3Wfbk {
  padding-top: 0.5em;
  border-radius: 0 0 2em 2em;
}



.Navigation_navigation__3o9b4 {
  grid-column: 3;
  grid-row: 2;
  position: absolute;
  margin-top: -2em;
  margin-left: 2em;

}

.Navigation_buttonActive__1EiHF {
  padding: 0.4em;
  font-weight: 600;
  color: rgb(253, 85, 36); 
  background-color: #ffffff;
  border: none;
  width: 13em;
  height: 2em;
  border-radius: 3em 3em 0 0;
  margin-right: -1.5em;
  border: 0.3em solid rgba(8, 67, 134, 0.199);
  border-bottom: none;
  z-index: 1;
  position: relative;
  box-shadow: -10px 0px 13px 1px #00000031, 10px -10px 13px 5px #0000001f,
    0px -30px 50px 13px rgba(0, 0, 0, 0);
  cursor: pointer;
}
.Navigation_buttonNonActive__3Lg4I {
  padding: 0.5em;
  font-weight: 600;
  color: rgba(247, 247, 247, 0.726);
  background-color: #5781cfc0;
  border: none;
  width: 11.5em;
  height: 2em;
  border-radius: 3em 3em 0 0;
  margin-right: -1.5em;
  border: 0.2em solid rgba(37, 66, 99, 0.705);
  border-bottom: none;
  z-index: 0;
  position: relative;
  cursor: pointer;
}

.Navigation_buttonNonActive__3Lg4I:hover {
  background-color: #5781cfd7;
  color: rgb(247, 247, 247);
}

.Navigation_buttonActive__1EiHF:focus {
  outline: none;
}
.Navigation_buttonNonActive__3Lg4I:focus {
  outline: none;
}


.tab-list {
  border: 1px solid rgb(253, 199, 36);

  /*
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  */
  
  background: rgba(48, 16, 7);
  background-color: #000000;
  padding-left: 0;

  white-space: nowrap;

  color: #a5a402;
  width: 100%;
}

@media only screen and (max-device-width: 480px) {
  .tab-list {

    overflow-x: scroll;
    overflow-y: hidden;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    -webkit-overflow-scrolling: touch;

    position: fixed;
    bottom: 0;
    background: rgba(48, 16, 7);
    /*    background-color: black;*/
    height: 55px;
    margin: 0px;
  }
}


/* Hide scrollbar for IE, Edge and Firefox */
.tab-list {
}

.title-item {
  display: inline-block;
  vertical-align: middle;
  list-style: none;
  width: 12em;
  text-align: left;  
}
.title-item img {
  height: 48px;
}

.tab-list-item img {
}

@media only screen and (max-device-width: 480px) {
  .tab-list-item img {
    padding-bottom: 4px;
    height: 24px;
  }
  .title-item img {
    height: 36px;
  }
  
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 1rem 0.5rem;

  vertical-align: middle;

}

@media only screen and (max-device-width: 480px) {
  .tab-list-item {
    height: 100%;
    font-weight: 600;
    font-size: 16px;

    vertical-align: middle;
  }
}


.tab-list-active {
  background-color: #ffffff22;
  border-width: 1px 1px 0 1px;
  color: black; /*rgb(253, 85, 36);*/
}




.Profile_info__393ap {
  padding-top: 0.5em;

  border-radius: 0 0 2em 2em;
}

.Profile_info__393ap p {
  padding-top: 1em;
  color: #f9f0b0b3;
  font-size: 0.7em;
}

@media only screen and (max-device-width: 400px) {
  .Profile_info__393ap p {
    font-size: 0.4em;
  }
}


.Profile_info__393ap button {
  color: #f9f0b0b3;
  font-size: 1em;
  border: rgb(253, 85, 36)  solid 0.05em; 
  

  /*  border: white solid 0.05em; */
  border-radius: 0.5em;
  height: 5em;
  width: 20em;
  cursor: pointer;
}

.Profile_info__393ap button:hover {
  background-color: rgb(48, 16, 7);
}

.Profile_info__393ap button:focus {
  outline: none;
}


.Profile_network__1vdbA p {
  color: rgb(253, 85, 36);
  font-size: 0.8em;
}

.Profile_info__393ap li {
  color: rgb(253, 85, 36);
  font-size: 0.8em;
}

@media only screen and (max-device-width: 400px) {
  .Profile_info__393ap li {
    font-size: 0.8em;
  }
}



.Profile_info__393ap ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}


.Profile_special__36Oma h1 {
  margin-top: 1.2em;
}
.Profile_icon__R1e2k {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}

.Profile_inputDiv__24lNA {
  padding-top: 0.1em;
  padding-bottom: 3em;
  height: 3em;
  align-items: center;
}


.Profile_trader__3P-xA {
  height: 3em;
  width: 23em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}


.Profile_input__45E1f {
  height: 3em;
  width: 15em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(139, 60, 23, 0.301);
}

.Profile_totals__2nuAa {
  margin-top: 3em;
  height: 3em;
}

.Profile_priceInfoBox__lAEBF {
  font-size: 10px;
  font-weight: 300;
  xcolor: lightcoral;

}
.Profile_gox__20VTO {
  cursor: pointer;
  font-size: 30px;
  font-weight: 800;
  color: lightblue;
}
.Profile_gox__20VTO span {
  font-size: 10px;
  font-weight: 300;
}

.Profile_gor__1YHlI {
  cursor: pointer;
  font-size: 30px;
  font-weight: 800;
  color: orange;
}
.Profile_gor__1YHlI span {
  font-size: 10px;
  font-weight: 300;
}

.Profile_go__1izYf {
  cursor: pointer;
  font-size: 30px;
  font-weight: 800;
}
.Profile_go__1izYf span {
  font-size: 10px;
  font-weight: 300;
}

.Profile_goMax__3Ifqk {
  padding-left: 1%;
  margin: 0;
}

.Profile_goMax__3Ifqk h5 {
  font-size: 2em;
}


.Profile_box__ZzSH3 div {
  width: 280px;
  margin: auto;
}

.Profile_box__ZzSH3 p {
  color: rgb(253, 85, 36);
  font-size: 0.8em;
}

.Profile_box__ZzSH3 a {
  color: rgb(253, 85, 36);
  text-decoration: none;
}


.Profile_boxInfo__3aF_A {
  border: rgb(253, 85, 36) solid 0.05em;
  padding: 1em;
  border-radius: 0.5em;
}

.Profile_boxHigh__1EjDe div {
  width: 280px;
  margin: auto;
}

.Profile_boxHigh__1EjDe p {
  color: #f9f0b0b3;
}
/*  xfont-size: 0.8em;*/


.Profile_digButton__2a7WF {
  background-color: black;
  color: orange;
  font-size: 1em;
  border: gold solid 0.5px;
  height: 2.5em;
  width: 20em;
  margin-top: 1em;
  cursor: pointer;
}

.Profile_digButton__2a7WF:hover {
  background-color: rgb(48, 16, 7);
}


.Profile_digButton__2a7WF:focus {
  outline: none;
 }


.Profile_digButton__2a7WF p {
  display: inline;
  position: relative;
  /*
  bottom: 0.5em;
  */
}



 .Profile_blockButton__3yqT2 {
   background-color: black; 
   /*
   background-image: url("../assets/button_bg.png");
   */
   color: orange;
   font-weight: 800;
   font-size: 1em;
   border: gold solid 0.5px;
   border-radius: 0; /* 0.5em; */
   height: 3em;
   width: 11em;
   margin-top: 1em;
   cursor: pointer;
 }

 .Profile_blockButton__3yqT2:hover {
   background-color: rgb(48, 16, 7);
 }


 .Profile_blockButton__3yqT2:focus {
   outline: none;
  }
  .Profile_blockButton__3yqT2 p {
    display: inline;
    position: relative;
    bottom: 0.5em;
  }



  .Profile_digIcon__1N4c7 {
    height: 2em;
    position: relative;
    top: 0.1em;
    right: 1em;
  }


.Profile_checkButton__2dAiJ {
  background-color: rgba(200,200,200,0.1);
  color: red;
  font-size: 1em;
  border: rgb(253, 85, 36) solid 0.05em;
  border-radius: 0; /* 0.5em; */
  height: 3em;
  width: 4em;
  margin-top: 1em;
  cursor: pointer;
}

.Profile_checkButton__2dAiJ:hover {
  background-color: rgb(48, 16, 7);
}

.Profile_checkButton__2dAiJ:focus {
  outline: none;
 }

 .Profile_checkButton__2dAiJ:disabled,
 .Profile_checkBbutton__3N9YV[disabled]{
   color: rgba(200,200,200,0.9);
 }

.Profile_checkIcon__2GOJc {
  height: 2em;
  position: relative;
  top: 0.1em;
  right: 1em;
}

.Profile_checkButton__2dAiJ p {
  display: inline;
  bottom: 0.5em;
}




.Profile_allButton__3TDp8 {
  background-color: black;
  color: orange;
  font-size: 1em;
  border: gold solid 0.5px;
  border-radius: 0; /* 0.5em; */
  height: 2.5em;
  width: 2.5em;
  margin-top: 1em;
  cursor: pointer;
}

.Profile_allButton__3TDp8:hover {
  background-color: rgb(48, 16, 7);
}


.Profile_allButton__3TDp8:focus {
  outline: none;
 }

.Profile_allButton__3TDp8 p {
   display: inline;
   position: relative;
 }



 .Profile_currencyButton__1Y-tr {
   background-color: rgba(253,85,36);
   color: #f9f0b0b3;
   font-size: 1em;
   border: rgb(253, 85, 36) solid 0.05em;
   border-radius: 0; /* 0.5em; */
  height: 2.5em;
   width: 9em;
   margin-top: 1em;
   cursor: pointer;
 }

 .Profile_currencyButton__1Y-tr:hover {
   background-color: rgb(48, 16, 7);
   background-color: rgb(0, 16, 7);
 }


 .Profile_currencyButton__1Y-tr:focus {
   outline: none;
  }

 .Profile_currencyButton__1Y-tr p {
    display: inline;
    position: relative;
  }



 .Profile_importButton__3Uhlk {
   background-color: rgb(48, 16, 7);
   background-color: rgb(0, 16, 7);
   color: #f9f0b0b3;
   font-size: 0.6em;
   border: rgb(253, 85, 36) solid 0.05em;
   border-radius: 0; /* 0.5em; */
   height: 3em;
   width: 5em;
   margin-top: 0em;
   margin-left: 1em;
   cursor: pointer;
 }

 .Profile_importButton__3Uhlk:hover {
   background-color: rgba(253,85,36);
 }


 .Profile_importButton__3Uhlk:focus {
   outline: none;
  }

 .Profile_importButton__3Uhlk p {
    display: inline;
    position: relative;
  }


 .Profile_moonButton__2b6ju {
   background-color: rgb(125,0,255); /*rgba(253,85,36);*/
   color: #f9f0b0b3;
   font-size: 1em;
   border: rgb(125, 0, 255) solid 0.05em;
   border-radius: 0.5em;
   height: 3em;
   width: 6em;
   margin-top: 1em;
   cursor: pointer;
 }

 .Profile_moonButton__2b6ju:hover {
   background-color: rgb(48, 0, 7);
 }


 .Profile_moonButton__2b6ju:focus {
   outline: none;
  }

 .Profile_moonButton__2b6ju p {
    display: inline;
    position: relative;
  }



 .Profile_rampButton__CE0QH {
   background-color: #21bf73;
   color: #f9f0b0b3;
   font-size: 1em;
   border: #21bf73 solid 0.05em;
   border-radius: 0; /* 0.5em; */
  height: 2.5em;
   width: 9em;
   margin-top: 1em;
   cursor: pointer;
 }

 .Profile_rampButton__CE0QH:hover {
   background-color: #10af63;
 }


 .Profile_rampButton__CE0QH:focus {
   outline: none;
  }

 .Profile_rampButton__CE0QH p {
    display: inline;
    position: relative;
  }



 .Profile_scanButton__2v1US {
   background-color: black;
   color: orange;
   font-size: 1em;
   border: gold solid 0.5px;
   height: 3em;
   width: 6em;
   margin-top: 1em;
   margin-bottom: 1em;
   cursor: pointer;
 }

 .Profile_scanButton__2v1US:hover {
   background-color: rgb(48, 16, 7);
 }


 .Profile_scanButton__2v1US:focus {
   outline: none;
  }

 .Profile_scanButton__2v1US p {
    display: inline;
    position: relative;
  }


.Profile_larger__3r-Bl {
  font-size: 1.2em;
}

.Profile_stone__ak3On {
  position: relative;
  top: 2em;
}

.Profile_animatestone__pB5em
{
  background-image: linear-gradient(
    50deg,
    #574315 0%,
    #7a2110 20%,
    #ff1361 30%,
    #fff800 40%,
    #ffffff 40%,
    #7a2110 80%,
    #574315 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: Profile_textclip__1ZvkN 2s linear infinite;
  display: inline-block;
      font-size: 190px;
}

@keyframes Profile_textclip__1ZvkN {
  to {
    background-position: -200% center;
  }
}

/*padding-top: 10em;*/

.Profile_header__3NUsv {
  height: 7em;

  _background-image: url(/static/media/iconHeader.ac04fa63.png);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 50%;

}




.Profile_logo__j8w4q {
  padding-top: 0.5em;
  background-image: url(/static/media/icon1500x.79ee9de1.png);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;

  border-radius: 0 0 2em 2em;
}

.Profile_logo__j8w4q p {
  padding-top: 1em;
  color: #f9f0b0b3;
  font-size: 0.7em;
}

@media only screen and (max-device-width: 400px) {
  .Profile_logo__j8w4q p {
    font-size: 0.4em;
  }
}

.Profile_logo__j8w4q .Profile_xx__3Lm2x {
  display: flex;
  justify-content: center;
  padding-top: 25%;
  padding-bottom: 25%;
}



.Profile_infologo__UyOv7 {
/*
  background-image: url("../assets/ares1500x30.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  */
  padding-left: 1em;
  padding-right: 1em;
}



.Profile_connectButton__24XYS {
  background-color: rgba(253,85,36);
  color: #f9f0b0b3;
  font-size: 1em;
  border: rgb(253, 85, 36) solid 0.05em;
  border-radius: 0; /* 0.5em; */
  height: 2.5em;
  width: 12em;
  margin-top: 1em;
  cursor: pointer;
}

.Profile_connectButton__24XYS:hover {
  background-color: rgb(48, 16, 7);
  background-color: rgb(0, 16, 7);
}

.Profile_connectButton__24XYS:focus {
  outline: none;
 }

.Profile_warning__3mI4i {
  font-weight: 700;
  font-size: 1em;
}



.Profile_connect__s8xAt a {
}

.Profile_connect__s8xAt img {
  height: 2.6em;
}

.Profile_connect__s8xAt button {
  background-color: rgba(0,0,0,0);
  color: #f9f0b0b3;
  font-size: 1em;
/*
  border: rgb(253, 85, 36) solid 0.05em;
  border-radius: 0.5em;
  */
  height: 3.5em;
  width: 12em;
  height: 5em;
  margin-top: 1em;
  cursor: pointer;
}

.Profile_connectlogo__325iV {
   width: 3em;
}

.Profile_connecttitle__3fY5O {
  width: 17em;
  vertical-align: middle;
  height: 2.6em;
  padding-left: 2em;
}


.Profile_dbgText__1bAdz {
  font-size: 0.5em;
  color: grey;
}
.Profile_dbgText__1bAdz button {
  background-color: rgba(0,0,0,0);
  color: rgb(253, 85, 36); 
  font-size: 0.5em;
  border: rgb(253, 85, 36)  solid 0.05em;
  border-radius: 0; /* 0.5em; */
  height: 2em;
  width: 12em;
  cursor: pointer;
}

.Profile_adviceBox__1WVz- {
  border: 0.05em solid rgb(253, 85, 36) ;
  padding: 1em;
  border-radius: 0.5em; 
}

.Profile_adviceBox__1WVz- p {
  margin: 0;
}

.Profile_pseudoButton__3JXC5 {
  padding-left: 2.3em;
}

.Profile_inputUnit__1rANe {
  display:inline-block;
  width: 4em;
  min-width: 4em;  
}
.Profile_inputWallet__3Dd7e {
  display:inline-block;
  width: 28em;
  min-width: 28em;  
  border-radius: 0.5em;
  border: 1px solid black;
  text-align: center;
}

.Profile_Signature__A5U94 {
  padding: 0.1em;
  border: 1px solid black;
  border-radius: 0.5em;
  margin:1em;
}


.Profile_Formular__2V3-P,
.Profile_wrapper__2IYuW {
  display: flex;
  flex: 1 1 auto;
}



.Profile_Formular__2V3-P  {
  flex-direction: column;
  border: none;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
}

.Profile_Formular__2V3-P input {
  padding: 1em;
  margin: 0.5em;
  border-radius: 0.5em;
  border: 1px solid black;
  vertical-align: middle;
}

.Profile_Formular__2V3-P select {
  padding: 0.5em;
  margin: 0.4em;
  width: 5em;
  border-radius: 0.5em;
  vertical-align: middle;
}

.Profile_boxOrder__1DJao {
  background-color: #f9f0b0b3;
  border-radius: 0.5em;
  border: 1px solid black;

}
.Profile_boxOrder__1DJao input {
  border-color: black;
  background-color: #f9f0b0b3;
}
.Profile_boxOrder__1DJao button {
  background-color:#f9f0b0b3;
  color: black;
  margin: 2em;
}
.Profile_boxOrder__1DJao p {
  color: black;
}
.Profile_boxOrder__1DJao span {
  color: black;
}
.Profile_boxOrder__1DJao h1 {
  color: black;
}
.Profile_boxOrder__1DJao h2 {
  color: black;
}
.Profile_boxOrder__1DJao h3 {
  color: black;
}

.Profile_contractOrder__1TfMN p {
  text-align: left;
  margin: 1em;
}
.Profile_contractDisclaimer__2n13i p {
  text-align: left;
  margin: 1em;
  font-size: 0.7em;
  color: black
}
/*
html, body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

background: #000;
*/

.Origin_logo__1nu7W {
  position: absolute;
  color: hsl(53, 97%, 46%);
  text-shadow:
    0 0 .5em #fff,
    0 0 .5em currentColor; 
}

.Origin_base__3avS2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Origin_rain__2ZI0s {
  display: flex;
}
.Origin_rain__2ZI0s p {
  line-height: 1;
}
.Origin_rain__2ZI0s span {
  display: block;
  width: 2vmax;
  height: 2vmax;
  font-size: 2vmax;
  color: #9bff9b11;
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
}

* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
  background: black; /*linear-gradient(#472712, #030201);*/
  color: white;

  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
}

